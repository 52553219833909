<template>
  <v-card outlined class="mb-5">
    <v-card-title>Getting started with Motapi</v-card-title>
    <v-card-text>
      Using Motapi NLP REST API is pretty straightforward. Our
      <a href="https://documentation.motapi.com"
        >OpenAPI generated documentation</a
      >
      should be everything you need.
    </v-card-text>
    <v-card-actions> </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: "Welcome",
};
</script>