<template>
  <v-card class="mx-auto" outlined>
    <v-card-title>Your account data</v-card-title>
    <v-list subheader two-line>
      <v-subheader>{{ email }}</v-subheader>
      <v-list-item>
        <v-list-item-avatar>
          <v-icon class="grey lighten-1" dark> mdi-account-cowboy-hat </v-icon>
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title>Your plan</v-list-item-title>

          <v-list-item-subtitle v-text="plan"></v-list-item-subtitle>
        </v-list-item-content>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon color="primary" v-bind="attrs" v-on="on">
              mdi-information</v-icon
            >
          </template>
          <span
            >For now, we only offer a free plan. Stay tuned for our future
            offerings.
          </span>
        </v-tooltip>
        <v-list-item-action>
          <v-btn disabled icon>
            <v-icon color="grey lighten-1">mdi-escalator-up</v-icon>
          </v-btn>
        </v-list-item-action>
      </v-list-item>

      <v-divider inset></v-divider>
      <v-list-item>
        <v-list-item-avatar>
          <v-icon class="grey lighten-1" dark> mdi-basket </v-icon>
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title>Credit remaining</v-list-item-title>

          <v-list-item-subtitle v-text="credit"></v-list-item-subtitle>
        </v-list-item-content>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon color="primary" v-bind="attrs" v-on="on">
              mdi-information</v-icon
            >
          </template>
          <span
            >Credit balance is updated every 5 minutes. While we're beta
            testing, no credit can be bought.
          </span>
        </v-tooltip>
        <v-list-item-action>
          <v-btn disabled icon>
            <v-icon color="grey lighten-1">mdi-basket-fill</v-icon>
          </v-btn>
        </v-list-item-action>
      </v-list-item>

      <v-divider inset></v-divider>
      <v-list-item>
        <v-list-item-avatar>
          <v-icon class="grey lighten-1" dark> mdi-key-variant </v-icon>
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title>Your API-key</v-list-item-title>
          <v-text-field
            readonly
            :prepend-icon="show3 ? 'mdi-eye' : 'mdi-eye-off'"
            :type="show3 ? 'text' : 'password'"
            name="input-10-2"
            label="Your Motapi API key"
            :value="apikey"
            class="input-group--focused"
            @click:prepend="show3 = !show3"
          ></v-text-field>
        </v-list-item-content>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon color="primary" v-bind="attrs" v-on="on">
              mdi-information</v-icon
            >
          </template>
          <span
            >Keep your key safe ! Each request to api.motapi.com must include a
            "x-api-key":"your-key" http header.
          </span>
        </v-tooltip>
        <v-list-item-action>
          <v-btn disabled icon>
            <v-icon color="grey lighten-1">mdi-autorenew</v-icon>
          </v-btn>
        </v-list-item-action>
      </v-list-item>
    </v-list>
  </v-card>
</template>

<script>
import "vuex";
export default {
  name: "AccountData",
  data() {
    return {
      // accountData: "qfjoqsfjoqjf",
      // credit: 10000,
      // apikey: "100sqdfsdqd5f456qsdef4zz400",
      show3: false,
    };
  },
  computed: {
    email: function () {
      return this.$store.getters.email;
    },
    plan: function () {
      return this.$store.getters.plan;
    },
    credit: function () {
      return this.$store.getters.credit;
    },
    apikey: function () {
      return this.$store.getters.apikey;
    },
    // accountData: function () {
    //   return this.$store.getters.accountData;
    // },
  },
};
</script>