<template>
  <v-container fluid>
    <v-dialog
      class="ma-10 pa-10 ma-md-auto"
      v-model="dialog"
      persistent
      max-width="700"
    >
      <v-img
        class="ma-10 pa-10 ma-md-auto"
        width="80%"
        src="@/assets/undraw_login.svg"
      />
      <EmailLinkSignIn
        v-if="!emailConfirm && !isUserLoggedIn"
        class="ma-10 pa-10 ma-md-auto"
      />
      <EmailConfirm
        v-if="emailConfirm && !isUserLoggedIn"
        class="ma-10 pa-10 ma-md-auto"
      />
      <SocialSignIn v-if="!isUserLoggedIn" class="ma-10 pa-10 ma-md-auto" />
    </v-dialog>
  </v-container>
</template>


<script>
import SocialSignIn from "@/components/auth/social/SocialSignIn";
import EmailLinkSignIn from "@/components/auth/EmailLinkSignIn";
import EmailConfirm from "@/components/auth/EmailConfirm";
import "vuex";
export default {
  name: "NewAuthDialog",
  components: {
    EmailLinkSignIn,
    SocialSignIn,
    EmailConfirm,
  },
  data: () => ({
    dialog: true,
    emailConfirmInput: "",
  }),
  computed: {
    emailConfirm: function () {
      return this.$store.getters.askForEmail;
    },
    accountDataDownloaded: function () {
      return this.$store.getters.accountDataDownloaded;
    },
    isUserLoggedIn: function () {
      return this.$store.getters.isUserLoggedIn;
    },
  },
};
</script>