
<template>
  <!-- Provides the application the proper gutter -->
  <v-container fluid>
    <v-sheet :elevation="elevation" class="mx-auto">
      <v-row>
        <v-col md="12"><Playground /></v-col>
      </v-row>
    </v-sheet>
  </v-container>
</template>

<script>
import "vuex";
import Playground from "@/components/Playground";
export default {
  name: "Play",
  components: {
    Playground,
  },
  methods: {
    getAccountData() {
      this.$store.dispatch("getAccountData");
    },
  },
};
</script>

<style lang="scss" scoped>
// # general account data : plan, remaining credit, hidden apikey
</style>
