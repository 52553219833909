<template>
  <form>
    <div v-if="!this.sent">
      <v-text-field
        required
        type="email"
        v-model="email"
        label="E-mail"
      ></v-text-field>
      <v-btn color="success" class="mr-4" @click="sendEmail(email)">
        Receive your sign in email link
      </v-btn>
    </div>
    <div v-if="sent">
      <v-alert type="success">
        An email was sent to {{ this.email }} with a sign in link. Check your
        mail box and see you in a minute.
      </v-alert>
    </div>
  </form>
</template>

<script>
import firebase from "firebase/app";
import "firebase/auth";
import "vuex";
export default {
  name: "EmailLinkSignIn",
  data() {
    return {
      email: "",
      sent: false,
      error: null,
    };
  },
  methods: {
    sendEmail(email) {
      var actionCodeSettings = {
        // URL you want to redirect back to. The domain (www.example.com) for this
        // URL must be whitelisted in the Firebase Console.
        url: "https://ui.motapi.com/finishSignIn",
        // This must be true.
        handleCodeInApp: true,
      };
      firebase
        .auth()
        .setPersistence(firebase.auth.Auth.Persistence.LOCAL)
        .then(() => {
          firebase
            .auth()
            .sendSignInLinkToEmail(email, actionCodeSettings)
            .then(() => {
              // The link was successfully sent. Inform the user.
              // Save the email locally so you don't need to ask the user for it again
              // if they open the link on the same device.
              // window.localStorage.setItem("emailForSignIn", this.email);
              this.$store.commit("setTempSignInEmail", this.email);
              this.sent = true;
            })
            .catch((error) => {
              this.error = error;
              // Some error occurred, you can inspect the code: error.code
            });
        });
    },
  },
};
</script>