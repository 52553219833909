var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"mx-auto",attrs:{"outlined":""}},[_c('v-card-title',[_vm._v("Your account data")]),_c('v-list',{attrs:{"subheader":"","two-line":""}},[_c('v-subheader',[_vm._v(_vm._s(_vm.email))]),_c('v-list-item',[_c('v-list-item-avatar',[_c('v-icon',{staticClass:"grey lighten-1",attrs:{"dark":""}},[_vm._v(" mdi-account-cowboy-hat ")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Your plan")]),_c('v-list-item-subtitle',{domProps:{"textContent":_vm._s(_vm.plan)}})],1),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"primary"}},'v-icon',attrs,false),on),[_vm._v(" mdi-information")])]}}])},[_c('span',[_vm._v("For now, we only offer a free plan. Stay tuned for our future offerings. ")])]),_c('v-list-item-action',[_c('v-btn',{attrs:{"disabled":"","icon":""}},[_c('v-icon',{attrs:{"color":"grey lighten-1"}},[_vm._v("mdi-escalator-up")])],1)],1)],1),_c('v-divider',{attrs:{"inset":""}}),_c('v-list-item',[_c('v-list-item-avatar',[_c('v-icon',{staticClass:"grey lighten-1",attrs:{"dark":""}},[_vm._v(" mdi-basket ")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Credit remaining")]),_c('v-list-item-subtitle',{domProps:{"textContent":_vm._s(_vm.credit)}})],1),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"primary"}},'v-icon',attrs,false),on),[_vm._v(" mdi-information")])]}}])},[_c('span',[_vm._v("Credit balance is updated every 5 minutes. While we're beta testing, no credit can be bought. ")])]),_c('v-list-item-action',[_c('v-btn',{attrs:{"disabled":"","icon":""}},[_c('v-icon',{attrs:{"color":"grey lighten-1"}},[_vm._v("mdi-basket-fill")])],1)],1)],1),_c('v-divider',{attrs:{"inset":""}}),_c('v-list-item',[_c('v-list-item-avatar',[_c('v-icon',{staticClass:"grey lighten-1",attrs:{"dark":""}},[_vm._v(" mdi-key-variant ")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Your API-key")]),_c('v-text-field',{staticClass:"input-group--focused",attrs:{"readonly":"","prepend-icon":_vm.show3 ? 'mdi-eye' : 'mdi-eye-off',"type":_vm.show3 ? 'text' : 'password',"name":"input-10-2","label":"Your Motapi API key","value":_vm.apikey},on:{"click:prepend":function($event){_vm.show3 = !_vm.show3}}})],1),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"primary"}},'v-icon',attrs,false),on),[_vm._v(" mdi-information")])]}}])},[_c('span',[_vm._v("Keep your key safe ! Each request to api.motapi.com must include a \"x-api-key\":\"your-key\" http header. ")])]),_c('v-list-item-action',[_c('v-btn',{attrs:{"disabled":"","icon":""}},[_c('v-icon',{attrs:{"color":"grey lighten-1"}},[_vm._v("mdi-autorenew")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }