import Vue from 'vue'
import App from './App.vue'
import store from './store'
import router from './router';
import vuetify from './plugins/vuetify';
import './plugins/firebase';
import VueCodeHighlight from 'vue-code-highlight';
import Donut from "vue-css-donut-chart";
import "vue-css-donut-chart/dist/vcdonut.css";

Vue.use(Donut);

Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')

Vue.use(VueCodeHighlight)