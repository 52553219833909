async function motapiRequest(apikey, endpoint, body) {
  const url = "https://api.motapi.com" + endpoint

  const response = await fetch(url, {
    method: "POST",
    body: JSON.stringify(body),
    headers: {
      "Content-Type": "application/json",
      "x-api-key": apikey,
    },
  })
  const result = await response.json(); return result;
}

async function getSummary(text, algo, number_of_sentences, apikey) {
  const endpoint = "/summary/" + algo
  const body = { text: text, number_of_sentences: number_of_sentences }
  return motapiRequest(apikey, endpoint, body);
}

async function getReadability(text, apikey) {
  const endpoint = "/readability/all"
  const body = { text: text }
  return motapiRequest(apikey, endpoint, body);
}

async function getEmotions(text, apikey) {
  const endpoint = "/emotion/intensity"
  const body = { text: text }
  return motapiRequest(apikey, endpoint, body);
}

async function getFullReport(text, apikey) {
  const endpoint = "/fullreport"
  const body = { text: text }
  return motapiRequest(apikey, endpoint, body);
}

export { getSummary, getReadability, getEmotions, getFullReport };