<template>
  <form>
    <div v-if="!this.sent">
      <v-text-field
        required
        type="email"
        v-model="email"
        label="E-mail"
      ></v-text-field>
      <v-btn color="success" class="mr-4" @click="confirmEmail(email)">
        Please confirm your email adress to sign in
      </v-btn>
    </div>
    <div v-if="sent">
      <v-progress-circular indeterminate color="primary"></v-progress-circular>
    </div>
  </form>
</template>

<script>
import "vuex";
export default {
  name: "EmailConfirm",
  data() {
    return {
      email: "",
      sent: false,
      error: null,
    };
  },
  methods: {
    confirmEmail(email) {
      this.$store.commit("setTempSignInEmail", email);
      this.sent = true;
      this.$store.dispatch("emailLinkSignIn");
    },
  },
};
</script>