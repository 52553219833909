<template>
  <v-app-bar app color="primary" dark>
    <v-btn href="https://motapi.com" onClick="_blank" text>
      <h1 class="mr-2">Motapi</h1>
      <!-- <v-icon>mdi-asterisk</v-icon> -->
    </v-btn>
    <h1><a href="https://motapi.com">Motapi</a></h1>
    <v-spacer></v-spacer>
    <v-btn to="/play" text>
      <span class="mr-2">Play</span>
    </v-btn>

    <v-spacer></v-spacer>
    <v-btn href="https://documentation.motapi.com" onClick="_blank" text>
      <span class="mr-2">Documentation</span>
    </v-btn>
    <v-spacer></v-spacer>
    <AccountMenu />
  </v-app-bar>
</template>

<script>
import AccountMenu from "@/components/account/AccountMenu";

export default {
  name: "AppBar",
  components: {
    AccountMenu,
  },
};
</script>