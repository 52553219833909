import Vue from "vue";
import Vuex from "vuex";
import User from "./user/user";
import App from "./app/app";
import Auth from "./auth/auth";
import VuexPersist from "vuex-persist";


Vue.use(Vuex);

const vuexLocal = new VuexPersist({
  key: "appShortName",
  storage: window.localStorage,
  reducer: state => ({
    user: state.user,
    auth: state.auth,
    app: state.app,
  })
});

export default new Vuex.Store({
  //strict: process.env.NODE_ENV !== 'production',

  modules: {
    user: User,
    app: App,
    auth: Auth,
  },
  state: {},
  mutations: {},
  actions: {},
  getters: {},
  plugins: [vuexLocal.plugin]
});