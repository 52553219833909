<template>
  <v-app>
    <LoadingAccountData
      v-if="isUserLoggedIn && !accountDataDownloaded && networkOnLine"
    />
    <AppBar v-if="isUserLoggedIn && accountDataDownloaded" />

    <!-- <v-navigation-drawer app v-if="isUserLoggedIn && networkOnLine"> -->
    <v-navigation-drawer app v-if="false">
      <!-- -->
    </v-navigation-drawer>

    <v-main>
      <router-view v-if="networkOnLine" />

      <Offline v-if="!networkOnLine" />
      <!-- <Login v-if="!isUserLoggedIn && networkOnLine" /> -->
      <!-- <NewAuthDialog v-if="!isUserLoggedIn && networkOnLine" />
      <Dashboard
        v-if="isUserLoggedIn && networkOnLine && accountDataDownloaded"
      />
      <LoadingAccountData
        v-if="isUserLoggedIn && networkOnLine && !accountDataDownloaded"
      /> -->
    </v-main>
  </v-app>
</template>

<script>
import "firebase/auth";
import { authChangeWatcher, checkUrlForEmailSignIn } from "@/auth/";
import Offline from "./views/Offline";
import AppBar from "@/components/AppBar";
import LoadingAccountData from "@/components/auth/LoadingAccountData";

import "vuex";

export default {
  name: "App",

  components: {
    Offline,
    AppBar,
    LoadingAccountData,
  },

  data: () => ({
    networkOnLine: true, // this.$store.getters.networkOnLine,
    //
  }),
  created() {
    console.log(window.location.href);
    authChangeWatcher();
    checkUrlForEmailSignIn();
  },

  computed: {
    isUserLoggedIn: function () {
      return this.$store.getters.isUserLoggedIn;
    },
    accountDataDownloaded: function () {
      return this.$store.getters.accountDataDownloaded;
    },
    // user: this.$store.getters.user.user,
  },
};
</script>
