export default {
  state: {
    appTitle: process.env.VUE_APP_TITLE,
    appShortTitle: process.env.VUE_APP_SHORT_TITLE,
    networkOnLine: true,
    currentError: null
    //data: appData
  },
  mutations: {
    setNetworkOnline: (state, value) => (state.networkOnLine = value),
    setCurrentError: (state, value) => (state.currentError = value)
  },

  actions: {},
  getters: {
    networkOnLine: state => state.networkOnLine,
    appData: state => state.data
  }
};
