<script>
import { Radar } from "vue-chartjs";
export default {
  extends: Radar,
  name: "Emotions",
  props: ["emotionsChartData"],
  mounted() {
    // Overwriting base render method with actual data.
    this.renderChart(this.emotionsChartData, {
      responsive: true,
      maintainAspectRatio: false,
    });
  },
};
</script>