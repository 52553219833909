<template>
  <v-menu v-model="menu" :close-on-content-click="false" offset-x>
    <template v-slot:activator="{ on, attrs }">
      <v-btn color="indigo" dark v-bind="attrs" v-on="on">
        <v-icon>mdi-account</v-icon>
      </v-btn>
    </template>

    <v-card>
      <v-list>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>{{ accountData.email }}</v-list-item-title>
            <v-list-item-subtitle
              >{{ accountData.plan }} plan - {{ accountData.credit }} remaining
              credits</v-list-item-subtitle
            >
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <v-divider></v-divider>

      <v-list>
        <v-list-item>
          <v-list-item-action>
            <v-btn to="/upgrade" text>
              <span class="mr-2">Upgrade</span>
            </v-btn>
          </v-list-item-action>
        </v-list-item>

        <v-list-item>
          <v-list-item-action>
            <v-btn to="/account" text>
              <span class="mr-2">Settings</span>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
        <v-list-item>
          <v-list-item-action>
            <v-btn to="/support" text>
              <span class="mr-2">Support</span>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
        <v-list-item>
          <v-list-item-action>
            <v-btn @click="logout" text>
              <span class="mr-2">Logout</span>
              <v-icon>mdi-logout</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </v-list>
    </v-card>
  </v-menu>
</template>

<script>
import "vuex";

export default {
  name: "AccountMenu",
  data: () => ({
    menu: false,
  }),
  methods: {
    logout() {
      this.$store.dispatch("logout");
    },
  },
  computed: {
    accountData: function () {
      return this.$store.getters.accountData;
    },
  },
};
</script>