<template>
  <v-card elevation="6" tile class="mx-auto">
    <v-card-title>Try it out</v-card-title>
    <v-card-subtitle
      >Each request decrements your request credit</v-card-subtitle
    >

    <v-form class="mx-10 pb-10">
      <v-textarea
        solo
        name="playtext"
        label="Paste or type your text here"
        v-model="text"
        id="play-text"
        cols="30"
        rows="15"
      ></v-textarea>
      <v-slider
        v-model="number_of_sentences"
        label="Number of sentences in the summary"
        thumb-label="always"
        :thumb-size="40"
        min="3"
        max="10"
      >
        <v-spacer></v-spacer>
      </v-slider>
      <v-spacer></v-spacer>
      <v-row>
        <v-col class="mx-10 align-content-space-around">
          <v-btn color="green" class="mr-4" @click="getSummary">
            Get summary
          </v-btn>
          <v-btn color="blue" class="mr-4" @click="getReadability">
            Get readability scores
          </v-btn>
          <v-btn color="purple" class="mr-4" @click="getEmotions">
            Get emotions intensities
          </v-btn></v-col
        >
      </v-row>
      <v-spacer></v-spacer>
      <v-row>
        <v-col class="mx-10">
          <Summary v-if="this.summary != null" :summary="this.summary"
        /></v-col>
        <v-col class="mx-10"
          ><v-card v-if="this.readability != null" elevation="10">
            <v-card-title>Readability</v-card-title>
            <Readability :readabilityData="this.readability" /> </v-card
        ></v-col>
        <v-col class="mx-10"
          ><v-card v-if="this.emotionsChartData != null" elevation="10">
            <v-card-title>Emotions intensity</v-card-title>
            <Emotions :emotionsChartData="this.emotionsChartData" /> </v-card
        ></v-col>
      </v-row>

      <v-alert
        v-if="this.error != null"
        border="bottom"
        colored-border
        type="error"
        elevation="2"
      >
        {{ this.error }}
      </v-alert>
    </v-form>
  </v-card>
</template>
<script>
import "vuex";
import { getSummary, getReadability, getEmotions } from "@/nlp/";
import Emotions from "@/components/play/Emotions";
import Readability from "@/components/play/Readability";
import Summary from "@/components/play/Summary";

export default {
  name: "Playground",
  components: {
    Emotions,
    Readability,
    Summary,
  },
  data() {
    return {
      valid: true,
      text: "",
      summary: null,
      readability: null,
      emotionsChartData: null,
      error: null,
    };
  },
  computed: {
    apikey: function () {
      return this.$store.getters.apikey;
    },
  },
  methods: {
    getSummary() {
      getSummary(this.text, "luhn", this.number_of_sentences, this.apikey)
        .then((result) => {
          console.log(result);
          this.summary = result.data.summary;
        })
        .catch(function (error) {
          // Handle backend error
          console.log(error);
          this.error = error;
        })
        .catch(function (error) {
          // Handle idtoken error
          console.log(error);
        });
    },
    getReadability() {
      getReadability(this.text, this.apikey)
        .then((result) => {
          console.log(result);
          this.readability = result.data;
        })
        .catch(function (error) {
          // Handle backend error
          console.log(error);
          this.error = error;
        });
    },
    getEmotions() {
      getEmotions(this.text, this.apikey)
        .then((result) => {
          console.log(result);
          this.emotionsChartData = {
            labels: Object.keys(result.data),
            datasets: [
              {
                label: "Emotions intensities",
                backgroundColor: "#f87979",
                data: Object.values(result.data),
              },
            ],
          };

          // const floatEmotions = result;
          // let intEmotions = {};
          // for (const [key, value] of Object.entries(floatEmotions)) {
          //   intEmotions[key] = value * 100;
          // }
          // this.emotions = intEmotions;
        })
        .catch(function (error) {
          // Handle backend error
          console.log(error);
          this.error = error.message;
        });
    },
  },
};
</script>
<style lang="scss" scoped>
// # general account data : plan, remaining credit, hidden apikey
</style>
