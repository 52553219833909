import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store';
import NewAuthDialog from "@/views/auth/NewAuthDialog";
import Dashboard from "@/views/Dashboard";
import Play from "@/views/Play";
import Account from "@/views/Account";
import Upgrade from "@/views/Upgrade";
import Support from "@/views/Support";

Vue.use(VueRouter)
const routes = [
  {
    path: '/auth',
    name: 'Auth',
    component: NewAuthDialog
  },
  {
    path: '/',
    name: "Home",
    component: Dashboard,
    meta: { requiresAuth: true },
  },
  {
    path: '/play',
    name: "Play",
    component: Play,
    meta: { requiresAuth: true },
  },
  {
    path: '/account',
    name: "Account",
    component: Account,
    meta: { requiresAuth: true },
  },
  {
    path: '/upgrade',
    name: "Upgrade",
    component: Upgrade,
    meta: { requiresAuth: true },
  },
  {
    path: '/support',
    name: "Support",
    component: Support,
    meta: { requiresAuth: true },
  },

]
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  // console.log("beforeEach router")
  // console.log(to.name)
  // console.log(to)
  // console.log(window.location.href)
  if (to.name !== 'Auth' && to.matched.some(record => record.meta.requiresAuth) && !store.getters.isUserLoggedIn) {
    console.log("redirected to auth")
    next({ name: 'Auth' })

  } else {
    next()
  }
})

export default router


// import Vue from 'vue'
// import VueRouter from 'vue-router'
// import Home from '../views/Home.vue'

// Vue.use(VueRouter)

// const routes = [
//   {
//     path: '/',
//     name: 'Home',
//     component: Home
//   },
//   {
//     path: '/about',
//     name: 'About',
//     // route level code-splitting
//     // this generates a separate chunk (about.[hash].js) for this route
//     // which is lazy-loaded when the route is visited.
//     component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
//   }
// ]

// const router = new VueRouter({
//   mode: 'history',
//   base: process.env.BASE_URL,
//   routes
// })

// export default router
