<template>
  <v-row>
    <v-col class="d-flex" cols="12" sm="6" xsm="12"
      ><v-btn @click="socialLogin('google')"
        ><v-icon left> mdi-google </v-icon>Join us with Google</v-btn
      >
    </v-col>
    <v-col class="d-flex" cols="12" sm="6" xsm="12">
      <v-btn @click="socialLogin('github')"
        ><v-icon left> mdi-github </v-icon>Join us with Github</v-btn
      ></v-col
    >
  </v-row>
</template>

<script>
export default {
  name: "SocialSignIn",
  methods: {
    socialLogin(provider) {
      this.$store.dispatch("providerLogin", provider);
    },
  },
};
</script>