<template>
  <v-card outlined class="mt-5">
    <v-card-title>Simple request example</v-card-title>
    <vue-code-highlight language="javascript" class="pl-10">
      <pre>
const text = "Very long text"

fetch("https://api.motapi.com/readability/all", {
  method: "POST",
  body: JSON.stringify({ text: text }),
  headers: {
    "Content-Type": "application/json",
    "x-api-key": "your-apikey",
  },
}).then((response) => {
  console.log(response.json());
})

// console.log(response.json());

{
  "flesch": 4.86,
  "kincaid": 33.2,
  "fog": 36.33,
  "smog": 0.0,
  "ari": 44.47,
  "coleman_liau": 14.31,
  "lix": 99.27,
  "linsear_write": 48.0
}
 </pre
      >
    </vue-code-highlight>
    <v-card-text
      ><a href="http://documentation.motapi.com" class="ml-10"
        >See the complete documentation</a
      ></v-card-text
    >
  </v-card>
</template>

<script>
import "vuex";
import { component as VueCodeHighlight } from "vue-code-highlight";
import "vue-code-highlight/themes/duotone-sea.css";
import "vue-code-highlight/themes/window.css";

export default {
  name: "RequestExample",
  components: {
    VueCodeHighlight,
  },
};
</script>