<template>
  <!-- Provides the application the proper gutter -->
  <v-container fluid>
    <v-sheet :elevation="elevation" class="mx-auto">
      <v-alert icon="mdi-wrench" prominent text type="info" dismissible>
        Motapi is in Beta. New endpoints are being worked on.
      </v-alert>
      <v-row>
        <v-col md="4"><Welcome /><AccountData /><RequestExample /> </v-col>
      </v-row>
      <!-- <v-alert icon="mdi-school" prominent text type="info">
      Once you're ready, jump into our documentation
      <v-btn>Get started</v-btn>
    </v-alert> -->
    </v-sheet>
  </v-container>
</template>

<script>
import "vuex";
import Welcome from "@/components/Welcome";
import AccountData from "@/components/AccountData";
import RequestExample from "@/components/RequestExample";
export default {
  name: "Dashboard",
  components: {
    Welcome,
    AccountData,
    RequestExample,
  },
  methods: {
    getAccountData() {
      this.$store.dispatch("getAccountData");
    },
  },
};
</script>

<style lang="scss" scoped>
// # general account data : plan, remaining credit, hidden apikey
</style>
