import * as firebase from "firebase/app";
import "firebase/auth";
import store from '@/store';
import router from '@/router';

function authChangeWatcher() {
  firebase.auth().onAuthStateChanged((user) => {
    if (user) {
      console.log("auth state changed");
      console.log("Is user loggedin ?" + store.getters.isUserLoggedIn);

      store.commit("setIsAuthenticated", true);
      console.log("Is user loggedin ?" + store.getters.isUserLoggedIn);
      store.commit("setUser", user);
      setTimeout(function () {
        store
          .dispatch("getAccountData")
          .then(() => router.push({ name: "Home" }));
      }, 500);
      // store
      //   .dispatch("getAccountData")
      //   .then(() => router.push({ name: "Home" }));
      // this.$store.dispatch("autoSignIn", user);
    } else {

      store.commit("setUser", undefined);
      store.commit("setIsAuthenticated", false);
      store.commit("setAccountData", null)
      router.push({ name: "Auth" });
    }
  });
}


function checkUrlForEmailSignIn() {
  if (firebase.auth().isSignInWithEmailLink(window.location.href)) {
    // Additional state parameters can also be passed via URL.
    // This can be used to continue the user's intended action before triggering
    // the sign-in operation.
    // Get the email if available. This should be available if the user completes
    // the flow on the same device where they started it.
    store.commit("setSignInUrl", window.location.href)
    var email = store.getters.tempSignInEmail;
    console.log("email from local storage" + email);
    if (!email) {
      // User opened the link on a different device. To prevent session fixation
      // attacks, ask the user to provide the associated email again. For example:
      store.commit("setAskForEmail", true);
    }
    // The client SDK will parse the code from the link for you.
    else {
      store.dispatch("emailLinkSignIn");
    }
  } else {
    console.log("No sign in url ");
  }
}





export { authChangeWatcher, checkUrlForEmailSignIn };