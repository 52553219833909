<template>
  <v-card elevation="10">
    <v-card-title>Summary</v-card-title>
    <v-card-text>{{ summary }}</v-card-text>
  </v-card>
</template>
<script>
export default {
  name: "Summary",
  props: ["summary"],
};
</script>