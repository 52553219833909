<template>
<div>
 <vue-code-highlight language="json" class="pl-10">
      <pre>
{{readabilityData}}
</pre>
</vue-code-highlight>
</div>
</template>

<script>
import { component as VueCodeHighlight } from "vue-code-highlight";
export default {
  name: "Readability",
    components: {
    VueCodeHighlight,
  },
  props: ["readabilityData"],
  data() {
    return {
      headers: [
        {
          text: "Readability methods",
          align: "start",
          sortable: false,
          value: "name",
        },
        { text: "Flesch", value: "flesch" },
        { text: "Kincaid", value: "kincaid" },
        { text: "fog", value: "fog" },
        { text: "Smog", value: "smog" },
        { text: "Ari", value: "ari" },
        { text: "Coleman Liau", value: "coleman_liau" },
        { text: "LIX", value: "lix" },
        { text: "Linsear Write", value: "linsear_write" },
      ],
      sections: [
        { label: "Red section", value: 25, color: "red" },
        { label: "Green section", value: 25, color: "green" },
        { label: "Blue section", value: 25, color: "blue" },
      ],
    };
  },
};
</script>