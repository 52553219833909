import firebase from "firebase/app";
import "firebase/auth";
export default {
  state: {
    user: undefined,
    account_data: null,
    isAuthenticated: false,
    tempSignInEmail: null,
    askForEmail: false,
    signInUrl: null,
  },
  mutations: {
    setUser: (state, value) => (state.user = value),
    setAccountData: (state, value) => (state.account_data = value),
    setIsAuthenticated: (state, value) => (state.isAuthenticated = value),
    setTempSignInEmail: (state, value) => (state.tempSignInEmail = value),
    setAskForEmail: (state, value) => (state.askForEmail = value),
    setSignInUrl: (state, value) => (state.signInUrl = value)
  },
  actions: {
    emailLinkSignIn: ({ commit }, user) => {
      commit("setUser", user);
      commit("setIsAuthenticated", true);
    },
    getAccountData: ({ commit }) => {
      firebase.auth().currentUser.getIdToken(/* forceRefresh */ true).then(function (idToken) {
        const url =
          "https://us-central1-motapi-f37cc.cloudfunctions.net/getUserData";

        fetch(url, {
          method: "POST",
          body: JSON.stringify({
            uid: idToken
          }),
          headers: {
            "Content-Type": "application/json"
          }
        })
          .then(response => response.json())
          .then((function (result) {
            if (result.success) {
              //console.log(result.user_data);
              //console.log(result.user_data.email);
              commit("setAccountData", result.user_data);
            } else {
              console.log("getAccountData firebase error : " + result.error);
            }
          }))
          .catch(function (error) {
            // Handle backend error
            console.log(error);
          })

      })
        .catch(function (error) {
          // Handle idtoken error
          console.log(error);
        })
    },

  },
  getters: {
    accountData: state => state.account_data,// (state.user !== null && state.user !== undefined)
    email: state => state.account_data.email,// (state.user !== null && state.user !== undefined)
    apikey: state => state.account_data.apikey, // (state.user !== null && state.user !== undefined)
    plan: state => state.account_data.plan, // (state.user !== null && state.user !== undefined)
    credit: state => state.account_data.credit, // (state.user !== null && state.user !== undefined)
    accountDataDownloaded: state => state.account_data != null // (state.user !== null && state.user !== undefined)
  }
};
