<template>
  <v-container fluid>
    <v-overlay>
      <div class="text-center">
        Please wait while we get your account data. If your account was just
        created, it might take a few seconds.
      </div>
      <v-progress-circular
        indeterminate
        :size="100"
        color="primary"
      ></v-progress-circular>
    </v-overlay>
  </v-container>
</template>

<script>
export default {
  name: "LoadingAccountData",
  // created() {
  //   let numberOfTries = 10;

  //   const tryToGetAccountData = setInterval(() => {
  //     this.$store.dispatch("getAccountData");
  //     numberOfTries = this.numberOfTries - 1;
  //     if (this.$store.getters.accountDataDownloaded || numberOfTries <= 0) {
  //       clearInterval(tryToGetAccountData);
  //     }
  //   }, 500);
  // },
};
</script>