<template>
  <v-card elevation="6" tile class="mx-auto">
    <v-card-title>Need help ?</v-card-title>
    <v-card-subtitle
      >You can use this form to fill an issue or simply send us a mail at
      <a href="mailto:support@motapi.com">support@motapi.com</a
      >.</v-card-subtitle
    >
    <v-form class="mx-10 pb-10">
      <v-textarea
        solo
        name="issue"
        label="Tell"
        v-model="issue"
        id="play-text"
        cols="15"
        rows="10"
      ></v-textarea>

      <v-spacer></v-spacer>
      <v-row>
        <v-col class="mx-10 align-content-space-around">
          <v-btn color="green" class="mr-4" @click="sendIssue">
            Send your message
          </v-btn>
        </v-col>
      </v-row>
      <v-spacer></v-spacer>
      <v-row>
        <v-col class="mx-10">
          <v-alert v-if="this.sent" type="success">
            Your issue was succesfully sent. We'll get back to you as soon as
            possible.
          </v-alert>
        </v-col>
      </v-row>
    </v-form>
  </v-card>
</template>

<script>
export default {
  name: "Support",
  data() {
    return {
      sent: false,
      issue: "",
    };
  },
  methods: {
    sendIssue: function () {
      fetch("https://firebasefunctionendpoint.io", {
        method: "post",
        body: JSON.stringify(this.issue),
      }).then(function (response) {
        return response.json();
      });
    },
  },
};
</script>