import firebase from "firebase/app";
import "firebase/auth";
export default {
  state: {
    isAuthenticated: false,
    tempSignInEmail: null,
    askForEmail: false,
    signInUrl: null,
  },
  mutations: {
    setIsAuthenticated: (state, value) => (state.isAuthenticated = value),
    setTempSignInEmail: (state, value) => (state.tempSignInEmail = value),
    setAskForEmail: (state, value) => (state.askForEmail = value),
    setSignInUrl: (state, value) => (state.signInUrl = value)
  },
  actions: {
    emailLinkSignIn: ({ commit, state }) => {
      firebase
        .auth()
        .signInWithEmailLink(state.tempSignInEmail, state.signInUrl)
        .then((result) => {
          commit("setUser", result.user);
          commit("setIsAuthenticated", true);
          commit("setAskForEmail", false);
          commit("setTempSignInEmail", null);
          commit("setSignInUrl", null)
          // You can access the new user via result.user
          // Additional user info profile not available via:
          // result.additionalUserInfo.profile == null
          // You can check if the user is new or existing:
          // result.additionalUserInfo.isNewUser
        })
        .catch(function (error) {
          console.log(error.code);
          // Some error occurred, you can inspect the code: error.code
          // Common errors could be invalid email and invalid or expired OTPs.
        });



    },
    userSignUpWithEmail({ commit }, { email, password }) {
      firebase
        .auth()
        .createUserWithEmailAndPassword(email, password)
        .then(result => {
          commit("setUser", result.user);
          commit("setIsAuthenticated", true);
        })
        .catch(() => {
          commit("setUser", null);
          commit("setIsAuthenticated", false);
        });
    },
    userLoginWithEmail({ commit }, { email, password }) {
      firebase
        .auth()
        .signInWithEmailAndPassword(email, password)
        .then(result => {
          commit("setUser", result.user);
          commit("setIsAuthenticated", true);
        })
        .catch(() => {
          commit("setUser", null);
          commit("setIsAuthenticated", false);
        });
    },
    anonymousLogin({ commit }) {
      firebase
        .auth()
        .setPersistence(firebase.auth.Auth.Persistence.SESSION)
        .then(() => {
          firebase
            .auth()
            .signInAnonymously()
            .then(result => {
              //console.log(result);
              commit("setUser", result.user);
              commit("setIsAuthenticated", true);
            })
            .catch(err => {
              commit("setUser", null);
              commit("setIsAuthenticated", false);
              commit("SetCurrentError", err);
              //console.log(err.message);
            });
        });
    },
    providerLogin({ commit }, loginProvider) {
      let provider = null;
      switch (loginProvider) {
        case "google":
          provider = new firebase.auth.GoogleAuthProvider();
          break;
        case "github":
          provider = new firebase.auth.GithubAuthProvider();
          break;
        case "facebook":
          provider = new firebase.auth.FacebookAuthProvider();
          break;
        case "apple":
          provider = new firebase.auth.AppleAuthProvider();
          break;

        default:
          break;
      }
      //firebase.auth().useDeviceLanguage();
      firebase
        .auth()
        .setPersistence(firebase.auth.Auth.Persistence.LOCAL)
        .then(() => {
          firebase
            .auth()
            .signInWithRedirect(provider)
            .then((result) => {
              console.log(result.user.email);
              commit("setUser", result.user);
              commit("setIsAuthenticated", true);
            })
            .catch(err => {
              commit("setUser", null);
              commit("setIsAuthenticated", false);
              //commit("SetCurrentError", err);
              console.log(err.message);
            });
        })
    },
    logout({ commit }) {
      firebase
        .auth()
        .signOut()
        .then(() => {
          commit("setUser", null);
          commit("setIsAuthenticated", false);
        })
        .catch(() => {
          commit("setUser", null);
          commit("setIsAuthenticated", false);
        });
    }
  },
  getters: {
    isUserLoggedIn: state => state.isAuthenticated, // (state.user !== null && state.user !== undefined)
    tempSignInEmail: state => state.tempSignInEmail,// (state.user !== null && state.user !== undefined)
    askForEmail: state => state.askForEmail,// (state.user !== null && state.user !== undefined)
    signInUrl: state => state.signInUrlLink,// (state.user !== null && state.user !== undefined)
  }
};
